"use strict";
import { gsap } from "gsap";

//burger menu//
const btnBurger = document.querySelector(".nav__button");
btnBurger.addEventListener("click", burgerMenu);
const links = document.querySelector(".nav__list");
links.addEventListener("click", burgerMenu);

function burgerMenu(){

  if(!document.body.hasAttribute("data-menu")){
    document.body.setAttribute("data-menu", true);
    if(!document.body.hasAttribute("data-menu")){
    var tl = gsap.timeline();
    tl.from(".nav", {x:400})
      .to(".nav", {x:0, duration: 0.5})
      .from(".nav__list--el1", {x:20, opacity: 0})
      .to(".nav__list--el1", {x:0, duration: 0.3, ease: "elastic", opacity: 1}, "-=0.3")
      .from(".nav__list--el2", {x: 20, opacity: 0})
      .to(".nav__list--el2", {x:0, duration: 0.3, ease: "elastic"}, "-=0.3")
      .from(".nav__list--el3", {x: 20, opacity: 0})
      .to(".nav__list--el3", {x:0, duration: 0.3, ease: "elastic"}, "-=0.3")
      .timeScale(2);
    }


    }else{
        document.body.removeAttribute("data-menu");
    }
}

//animations clic par page
const animHome = document.querySelector(".nav__list--el1");
animHome.addEventListener("click", animationHome);
const animHomeAL = document.querySelector(".link");
animHomeAL.addEventListener("click", animationHome);
const logo = document.querySelector(".logo");
logo.addEventListener("click", animationHome);


function animationHome(){
  var tl = gsap.timeline();
  tl.from(".title--first", {y:-20, opacity: 0})
  tl.to(".title--first", {y:0, duration: 0.2, opacity: 1})
  tl.from(".title--second", {y:-20, opacity: 0})
  tl.to(".title--second", {y:0, duration: 0.2, opacity: 1})
}

const animCase = document.querySelector(".nav__list--el5");
animCase.addEventListener("click", animationCase);
function animationCase(){
  var tl = gsap.timeline();
  tl.from(".title--third", {y:-20, opacity: 0})
  tl.to(".title--third", {y:0, duration: 0.2, opacity: 1})
}

const animContact = document.querySelector(".nav__list--el7");
animContact.addEventListener("click", animationContact);
function animationContact(){
  var tl = gsap.timeline();
  tl.from(".contact__list--el01", {y:-20, opacity: 0})
  tl.to(".contact__list--el01", {y:0, duration: 0.2, opacity: 1})
  tl.from(".contact__list--el02", {y:-20, opacity: 0})
  tl.to(".contact__list--el02", {y:0, duration: 0.2, opacity: 1})
  tl.from(".contact__list--el03", {y:-20, opacity: 0})
  tl.to(".contact__list--el03", {y:0, duration: 0.2, opacity: 1})
  tl.from(".contact__list--el04", {y:-20, opacity: 0})
  tl.to(".contact__list--el04", {y:0, duration: 0.2, opacity: 1})
}

const animPropos = document.querySelector(".nav__list--el6");
animPropos.addEventListener("click", animationPropos);
function animationPropos(){
  var tl = gsap.timeline();
  tl.from(".title--third", {y:-20, opacity: 0})
  tl.to(".title--third", {y:0, duration: 0.2, opacity: 1})
}

//date//
let date = new Date().getFullYear();
document.getElementById("annee").innerHTML = date;

//afficher bonne section
let buttons = document.querySelectorAll(".nav__list--button");
for(let button of buttons){
	button.addEventListener("click", (e)=>{
		let tabs = document.querySelectorAll(".tabContent");
		for(let tab of tabs){
			tab.classList.add("hidden");
		}

		let tabId = e.currentTarget.getAttribute("data-tab");
		let currentTab = document.getElementById(tabId);
		currentTab.classList.remove("hidden");
    // console.warn(tabId);

    if ((tabId == "content2") && (window.matchMedia('(min-width: 1280px').matches)) {
      const sliderAfter = document.querySelector(".slider__list--after");
      const sliderBefore = document.querySelector(".slider__list--prev");

      sliderAfter.classList.add("hidden");
      sliderBefore.classList.add("hidden");
    }

  });
}

const iframe = document.querySelector(".iframe");
if (iframe){
  links.style.backgroundColor = "inherit";
}






//slider projet
let nb = 0;
const nf = 12;

const btnNext = document.querySelector(".slider__list--after");
const btnPrev = document.querySelector(".slider__list--prev");

btnNext.addEventListener('click', next);
btnPrev.addEventListener('click', prev);

// aller vers la page suivante
function next () {
  const elShow = document.querySelector('.slider__el--show');
  const elNext = elShow.nextElementSibling;

  elShow.classList.remove('slider__el--show');

  if (elNext) {
    elNext.classList.add('slider__el--show');
    if (nb <= nf) {
      nb++;
    }

    var tl = gsap.timeline();
    tl.from(".projets__list--el", {opacity: 0})
    tl.to(".projets__list--el", {duration: 0.2, opacity: 1})

  } else {
    const elFirst = elShow.parentNode.firstElementChild;
    elFirst.classList.add('slider__el--show');
  }
}

// retourner en arrière
function prev () {
  const elShow = document.querySelector('.slider__el--show');
  const elPrev = elShow.previousElementSibling;

  elShow.classList.remove('slider__el--show');

  if (elPrev) {
    elPrev.classList.add('slider__el--show');
    elPrev.classList.remove('hidden');
    if (nb <= nf) {
      nb--;
    }

    var tl = gsap.timeline();
    tl.from(".projets__list--el", {opacity: 0})
    tl.to(".projets__list--el", {duration: 0.2, opacity: 1})

  } else {
    const elLast = elShow.parentNode.lastElementChild;
    elLast.classList.add('slider__el--show');
  }
}


//element après photos
let nbPhoto = 0;
const nfPhoto = 21;

const btnPrevPhoto = document.querySelector('.sliderPhoto__list--prev');
const btnNextPhoto = document.querySelector('.sliderPhoto__list--after');
btnNextPhoto.addEventListener('click', nextPhoto);
btnPrevPhoto.addEventListener('click', prevPhoto);

function nextPhoto () {
  const elShowPhoto = document.querySelector('.sliderPhoto__el--show');
  const elNextPhoto = elShowPhoto.nextElementSibling;

  elShowPhoto.classList.remove('sliderPhoto__el--show');

  if (elNextPhoto) {
    elNextPhoto.classList.add('sliderPhoto__el--show');
    if (nbPhoto <= nfPhoto) {
      nbPhoto++;
    }
    if ((nbPhoto > 0) && (nbPhoto <= 21)){
      btnPrevPhoto.classList.remove('hidden');
    }

    var tl = gsap.timeline();
    tl.from(".photo__list--el", {opacity: 0})
    tl.to(".photo__list--el", {duration: 0.2, opacity: 1})

  } else {
    const elFirstPhoto = elShowPhoto.parentNode.firstElementChild;
    elFirstPhoto.classList.add('sliderPhoto__el--show');
  }
}

//retourner en arrière
function prevPhoto () {
  const elShowPhoto = document.querySelector('.sliderPhoto__el--show');
  const elPrevPhoto = elShowPhoto.previousElementSibling;

  elShowPhoto.classList.remove('sliderPhoto__el--show');

  if (elPrevPhoto) {
    elPrevPhoto.classList.add('sliderPhoto__el--show');
    elPrevPhoto.classList.remove('hidden');
    if (nbPhoto <= nfPhoto) {
      nbPhoto--;
    }

    var tl = gsap.timeline();
    tl.from(".photo__list--el", {opacity: 0})
    tl.to(".photo__list--el", {duration: 0.2, opacity: 1})

  } else {
    const elLastPhoto = elShowPhoto.parentNode.lastElementChild;
    elLastPhoto.classList.add('sliderPhoto__el--show');
  }
}



//slider projet
let nbCrea = 0;
const nfCrea = 12;

const btnNextCrea = document.querySelector(".sliderCrea__list--after");
const btnPrevCrea = document.querySelector(".sliderCrea__list--prev");

btnNextCrea.addEventListener('click', nextCrea);
btnPrevCrea.addEventListener('click', prevCrea);

// aller vers la page suivante
function nextCrea () {
  const elShowCrea = document.querySelector('.sliderCrea__el--show');
  const elNextCrea = elShowCrea.nextElementSibling;

  elShowCrea.classList.remove('sliderCrea__el--show');

  if (elNextCrea) {
    elNextCrea.classList.add('sliderCrea__el--show');
    if (nbCrea <= nfCrea) {
      nbCrea++;
    }

    var tl = gsap.timeline();
    tl.from(".crea__list--el", {opacity: 0})
    tl.to(".crea__list--el", {duration: 0.2, opacity: 1})

  } else {
    const elFirstCrea = elShowCrea.parentNode.firstElementChild;
    elFirstCrea.classList.add('sliderCrea__el--show');
  }
}

// retourner en arrière
function prevCrea () {
  const elShowCrea = document.querySelector('.sliderCrea__el--show');
  const elPrevCrea = elShowCrea.previousElementSibling;

  elShowCrea.classList.remove('sliderCrea__el--show');

  if (elPrevCrea) {
    elPrevCrea.classList.add('sliderCrea__el--show');
    elPrevCrea.classList.remove('hidden');
    if (nb <= nf) {
      nb--;
    }

    var tl = gsap.timeline();
    tl.from(".crea__list--el", {opacity: 0})
    tl.to(".crea__list--el", {duration: 0.2, opacity: 1})

  } else {
    const elLastCrea = elShowCrea.parentNode.lastElementChild;
    elLastCrea.classList.add('sliderCrea__el--show');
  }
}
